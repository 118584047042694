import { useEffect, useState } from 'react';
import { addPageLoadEventListener } from '../../../utils/dom';
import { HeroData, QuickViewContextProviderProps, QuickViewHookData, QuickViewLoadingState } from '../types';
import { getListingIdsFromCartDetails, getListingHeroData } from '../utils/listing-details';
import { ListingIdToHeroDataMap } from '../../../../../common/utils/hydration';
import { useAjax } from '../../../utils/ajax';
import { startLazyLoadingQuickView } from '../components/quick-view-popup-lazy/utils/utils';
import { useAppMetaContextData } from '../../../utils/app-meta-context';
import { getActionTrackingData } from '../../../utils/tracking-utils';
import { trackPulsar } from '../../../utils/tracking';
import {
    dropXTTagsForQuickView,
    getDeviceAndMembershipQuickViewEP,
    isInQuickViewTreatment
} from '../utils/experimentation';
import { useFeatureFlagsData } from '../../../utils/experiments';

export function useQuickView({ cartState }: QuickViewContextProviderProps): QuickViewHookData {
    const featureFlagsData = useFeatureFlagsData();
    const qtags = featureFlagsData?.qtags;
    const { appMeta, meta } = useAppMetaContextData();
    const [loadingState, setLoadingState] = useState(QuickViewLoadingState.NotStarted);
    const [listingIdToHeroData, setListingIdToHeroData] = useState<ListingIdToHeroDataMap>({});
    const [currentHeroData, setCurrentHeroData] = useState<HeroData | null>(null);
    const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

    const isQuickViewEPData = getDeviceAndMembershipQuickViewEP(featureFlagsData, appMeta);
    const maxViexpioCallsPerRequest = cartState?.appConfig?.maxViexpioCallsPerRequest;
    const listingIds = getListingIdsFromCartDetails(cartState?.modules?.cartDetails, maxViexpioCallsPerRequest);

    const makeAjaxCall = useAjax();
    useEffect(() => {
        let ignore = false;
        const getAndSetItemDetails = async() => {
            if (!ignore) {
                const data = await getListingHeroData(makeAjaxCall, listingIds);
                if (data) {
                    setListingIdToHeroData(data);
                }
                setLoadingState(QuickViewLoadingState.PageLoaded);
            }
        };

        if (listingIds?.length && isQuickViewEPData) {
            addPageLoadEventListener(() => {
                dropXTTagsForQuickView(isQuickViewEPData, qtags);
                if (isInQuickViewTreatment(isQuickViewEPData)) {
                    startLazyLoadingQuickView();
                    void getAndSetItemDetails();
                }
            });
        }
        return () => { ignore = true; };
    }, []);

    // Quick View Popup State Getter and Setters
    const getIsQuickViewOpen = () => isQuickViewOpen;
    const openQuickView = () => {
        setIsQuickViewOpen(true);
    };
    const closeQuickView = () => {
        const trackingData = getActionTrackingData({
            refActionName: 'CLOSE_QUICK_VIEW',
            isGuest: appMeta?.isGuest,
            shoppingCartId: meta?.shoppingCartId
        });
        trackPulsar(trackingData?.[0]);
        setIsQuickViewOpen(false);
        setCurrentHeroData(null);
    };

    // Quick View Loading Status
    const shouldShowQuickViewAction = () => loadingState === QuickViewLoadingState.Done;
    const shouldShowQuickViewPopup = () => isInQuickViewTreatment(isQuickViewEPData) && (
        loadingState === QuickViewLoadingState.PageLoaded
        || loadingState === QuickViewLoadingState.Done
    );
    const setShouldShowQuickViewAction = () => setLoadingState(QuickViewLoadingState.Done);

    // Quick View's Hero Data Getter & Setter
    const getQuickViewHeroData = () => currentHeroData;
    const setQuickViewHeroData = async(listingId?: string) => {
        if (!listingId) {
            return;
        } else if (listingIdToHeroData[listingId]) {
            openQuickView();
            setCurrentHeroData(listingIdToHeroData[listingId]);
        } else {
            openQuickView();
            const data = await getListingHeroData(makeAjaxCall, [listingId]);
            if (data?.[listingId]) {
                setCurrentHeroData(data[listingId]);
                setListingIdToHeroData({ ...listingIdToHeroData, [listingId]: data[listingId] });
            } else {
                setIsQuickViewOpen(false);
                setCurrentHeroData(null);
            }
        }
    };


    return {
        closeQuickView,
        getIsQuickViewOpen,
        getQuickViewHeroData,
        setQuickViewHeroData,
        openQuickView,
        setShouldShowQuickViewAction,
        shouldShowQuickViewAction,
        shouldShowQuickViewPopup
    };
}
