import { EbayPageNoticeTitle } from '@ebay/ui-core-react/ebay-page-notice';
import { Action } from '@ebay/experience-types-ebay';
import { useI18n } from '../../../../utils/i18n';
import { TextDisplay } from '../../../text-display';
import { AlertLevel } from '../../types';
import { useAppMetaContextData } from '../../../../utils/app-meta-context/hooks';
import { TEST_IDS } from '../../../../utils/test-ids';
import { triggerSignInOTPAuthFlow } from '../../../../utils/auth-modal';
import { useFeatureFlagsData } from '../../../../utils/experiments/hooks';
import { AlertContentProps } from './types';

export function AlertContent({ errorState, level, notification }: AlertContentProps) {
    const i18n = useI18n();
    const { appMeta, meta } = useAppMetaContextData();
    const featureFlagsData = useFeatureFlagsData();

    if (!errorState && !notification?.message?.length) {
        return null;
    }

    if (errorState) {
        const ErrorMessageWrapper = level === AlertLevel.Page ? EbayPageNoticeTitle : 'p';
        return (
            <ErrorMessageWrapper className="error-state">
                {i18n.getGenericErrorMessage()}
            </ErrorMessageWrapper>
        );
    }

    // Inline messages should not use header tags, only page notices should.
    let title;
    let messages = notification?.message;
    if (level === AlertLevel.Page) {
        title = notification?.message?.[0];
        messages = notification?.message?.slice(1);
    }

    const handleClick = (action?: Action) => {
        if (action?.name === 'SIGN_IN') {
            triggerSignInOTPAuthFlow(true, appMeta,
                meta?.signInPageUrlObj?.signInPageUrl || action?.URL,
                featureFlagsData);
        } else if (action?.URL) {
            window.location.href = action.URL;
        }
    };

    return (
        <>
            {title && (
                <EbayPageNoticeTitle>
                    <TextDisplay
                        data={title}
                        doAction={handleClick}
                        testId={TEST_IDS.signIn}
                    />
                </EbayPageNoticeTitle>
            )}

            {messages?.map((message, index) => (
                <p key={index}>
                    <TextDisplay data={message} />
                </p>
            ))}
        </>
    );
}
