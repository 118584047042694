import { ChangeEvent, useEffect, useState } from 'react';
import { getPageLoadEventName } from '../../../../common/utils/dom';
import { isPageLoadEventFired } from '../../../../common/utils/dom';
import { LineItem, Seller, ShoppingCartListingSummary } from '../../../../../common/utils/hydration';
import { BulkActionContextProviderProps, BulkActionItemsMap, BulkActionsHookData, BulkActionsModules, BulkActionsSelectedItems, PricingCostSummary } from '../types';
import {
    buildCartSummaryDiscountPricelines,
    buildCartSummaryPricelines,
    buildSelectionsRequestPayload,
    buildSellerObject,
    convertBulkActionsMapToObject,
    extractLineItemsDetailsFromSellerbuckets,
    extractQuantityFromListingSummary,
    formatBulkXoTotal,
    getCurrencyPrefix,
    isBulkCheckoutEnabled
} from '../utils/utils';
import { useAppMetaContextData } from '../../../utils/app-meta-context/hooks';
import { useAjax } from '../../../utils/ajax/hooks';

export function useBulkActions({ initialState, cartState, onBulkSelection }: BulkActionContextProviderProps): BulkActionsHookData {
    // Config values
    const shoppingCartBulkActionsTourTip = initialState?.featureFlagsData?.shoppingCartBulkActionsTourTip || false;
    const bulkActionsServiceCallTimeout = initialState?.appConfig?.bulkActionsServiceCallTimeout || 250;

    const shoppingCartId = initialState?.meta?.shoppingCartId;
    const shopper = cartState?.meta?.shopper;

    // State variables
    const [bulkActionItemsMap, setBulkActionItemsMap] = useState <BulkActionItemsMap>(new Map());

    const {
        bulkActionItemsMap: selectedItemsMap,
        bulkXoDisplayPrice = 0,
        bulkXoQuantity = 0,
        quantityTotal = 0,
        allItemsMap
    } = extractLineItemsDetailsFromSellerbuckets(
        cartState?.modules?.cartDetails?.sellerBuckets,
        Array.from(bulkActionItemsMap.keys())
    ); // Fetch line item details used in bulk XO default values

    const cartBulkXoEnabled = isBulkCheckoutEnabled(initialState?.featureFlagsData?.cartBulkXoEnabled, initialState?.appMeta?.isGuest, allItemsMap?.size);
    const makeAjaxCall = useAjax();

    const [fullCartMap, setFullCartMap] = useState<BulkActionItemsMap>(new Map(allItemsMap));
    const [selectedBulkActionItems, setSelectedBulkActionItems] = useState<BulkActionsSelectedItems>([]);
    const [activeBulkActionsModule, setActiveBulkActionsModule] = useState<BulkActionsModules>(cartBulkXoEnabled ? 'CART' : null);
    const [selectAllCheckboxChecked, setSelectAllCheckboxChecked] = useState<boolean>(allItemsMap?.size === selectedItemsMap?.size);
    const [selectAllClicked, setSelectAllCheckboxClicked] = useState<boolean>(false);
    const [shouldRenderBulkActionsTourtip, setShouldRenderBulkActionsTourtip] = useState<boolean>(false);
    const [tourtipManuallyClosed, setTourtipManuallyClosed] = useState<boolean>(false);
    const [buttonQuantitySelected, setButtonQuantitySelected] = useState<number>(cartBulkXoEnabled ? bulkXoQuantity : 0);
    const [showItemDiscountsPricelinesLoadingState, setShowItemDiscountsPricelinesLoadingState] = useState<boolean>(false);
    const [showItemPricelinesLoadingState, setShowItemPricelinesLoadingState] = useState<boolean>(false);


    const [timer, setTimer] = useState<NodeJS.Timer | undefined>();

    const [itemDiscountsPricelinesRequestController, setItemDiscountsPricelinesRequestController] = useState<XMLHttpRequest>();
    const [itemPricelinesRequestController, setItemPricelinesRequestController] = useState<XMLHttpRequest>();


    const { meta } = useAppMetaContextData();
    const locale = meta?.locale;
    const currencyPrefix = getCurrencyPrefix(cartState?.modules?.cartSummary?.total?.value);
    const formattedBulkXoTotal = formatBulkXoTotal(bulkXoDisplayPrice, locale, currencyPrefix);
    const [bulkXoOrderTotal, setBulkXoOrderTotal] = useState<string>(cartBulkXoEnabled ? formattedBulkXoTotal : '');

    const [pricingCostSummary, setPricingCostSummary] = useState<PricingCostSummary>({});
    const defaultPriceSummary = buildCartSummaryPricelines(cartState?.modules?.cartSummary?.summaryItems, cartState?.modules?.cartSummary?.total);
    const [costSummary, setCostSummary] = useState<PricingCostSummary>(defaultPriceSummary);

    const getItemPriceDetailsFromService = (itemMap: BulkActionItemsMap, selectAll = false) => {
        // Cancel any previous requests
        itemDiscountsPricelinesRequestController?.abort();
        itemPricelinesRequestController?.abort();

        // COPS request to fetch item pricelines
        makeAjaxCall('/api/cops', {
            itemMap: convertBulkActionsMapToObject(itemMap),
            shopper,
            shoppingCartId
        }, false, true, itemDiscountsPricelinesRequestController).
            then(response => {
                setShowItemDiscountsPricelinesLoadingState(false);
                const result = response?.modules?.pricelines?.result;
                if (result === 'SUCCESS') {
                    const proFormaOrderDetails = response?.modules?.pricelines?.proFormaOrder;
                    const formattedPriceLines = buildCartSummaryDiscountPricelines(proFormaOrderDetails, locale, currencyPrefix);
                    setPricingCostSummary(formattedPriceLines);
                }
            })
            .catch((error: Error) => {
                setShowItemDiscountsPricelinesLoadingState(false);
            });

        const selectPayload = {
            ...buildSelectionsRequestPayload(itemMap, fullCartMap, selectAll)
        };

        // Select Cartexsvc call for updating item selections with ShopCase
        makeAjaxCall('/api/select', selectPayload, false, true, itemPricelinesRequestController)
            .then(response => {
                if (onBulkSelection) {
                    onBulkSelection(response);
                }

                const formattedPriceLines = buildCartSummaryPricelines(response?.modules?.cartSummary?.summaryItems, response?.modules?.cartSummary?.total);
                setCostSummary(formattedPriceLines);
                setShowItemPricelinesLoadingState(false);
            })
            .catch((error: Error) => {
                setShowItemPricelinesLoadingState(false);
            });

    };

    const setTourtipLocalStorage = () => {
        if (!tourtipManuallyClosed && !localStorage.getItem('render-cart-bulk-actions-tourtip')) {
            setShouldRenderBulkActionsTourtip(true);
        } else if (shouldRenderBulkActionsTourtip && localStorage.getItem('render-cart-bulk-actions-tourtip')) {
            setShouldRenderBulkActionsTourtip(false);
        } else if (!shouldRenderBulkActionsTourtip && !localStorage.getItem('render-cart-bulk-actions-tourtip')) {
            localStorage.setItem('render-cart-bulk-actions-tourtip', 'false');
        }
    };

    const updateBulkXoOrderTotal = (bulkXoPrice: number): void => {
        const formattedBulkXoTotal = formatBulkXoTotal(bulkXoPrice, locale, currencyPrefix);
        setBulkXoOrderTotal(formattedBulkXoTotal);
    };

    useEffect(() => {
        if ( !isPageLoadEventFired()) {
            window.addEventListener(getPageLoadEventName(), setTourtipLocalStorage);
        } else {
            setTourtipLocalStorage();
        }
    }, [shouldRenderBulkActionsTourtip]);

    useEffect(() => {
        /**
         * This is to handle case when item quantity is updated during Bulk XO. This covers 2 cases -
         * a) Item whose qty is updated is checked
         * b) Item whose qty is updated and is NOT checked
         */
        if (cartBulkXoEnabled) {
            const {
                bulkActionItemsMap: selectedItemsMap,
                bulkXoDisplayPrice = 0,
                bulkXoQuantity = 0,
                allItemsMap
            } = extractLineItemsDetailsFromSellerbuckets(cartState?.modules?.cartDetails?.sellerBuckets, []);

            if (selectedItemsMap) {
                setBulkActionItemsMap(selectedItemsMap);
            }
            if (allItemsMap) {
                setFullCartMap(allItemsMap);
            }
            setButtonQuantitySelected(bulkXoQuantity);
            updateBulkXoOrderTotal(bulkXoDisplayPrice);
            const pricelines = buildCartSummaryPricelines(cartState?.modules?.cartSummary?.summaryItems, cartState?.modules?.cartSummary?.total);
            setCostSummary(pricelines);

            /**
             * When items are moved to/from SFL, if an unchecked item is left in cart, we are manually checking the item.
             * This block should be removed once this behavior is supported from ShopCase
             * Condition to execte this if block - Total items in cart 1 AND no items selected
             */
            if (selectedItemsMap?.size === 0 && allItemsMap?.size === 1) {
                try {
                    const singeItemCheckbox = document.querySelector('.cart-bulk-actions-checkbox input') as HTMLElement;
                    if (singeItemCheckbox) {
                        setTimeout(() => {
                            singeItemCheckbox?.click();
                        }, 200);
                    }
                } catch (e) {
                    // No checkbox at item level found to check
                }
            }
        }
    }, [cartState]);

    useEffect(() => {
        if (window) {
            // Initialize request controllers for async pricing requests
            setItemDiscountsPricelinesRequestController(new XMLHttpRequest());
            setItemPricelinesRequestController(new XMLHttpRequest());
        }
    }, []);

    const handleBulkActionServiceCall = (itemMap: BulkActionItemsMap, selectAll = false) => {
        if (timer) {
            clearTimeout(timer);
        }
        setShowItemDiscountsPricelinesLoadingState(true);
        setShowItemPricelinesLoadingState(true);
        setPricingCostSummary({});
        setCostSummary({});

        setTimer(setTimeout(() => {
            getItemPriceDetailsFromService(itemMap, selectAll);
        }, bulkActionsServiceCallTimeout));
    };

    const onBulkActionCheckboxChange = (
        e: ChangeEvent<HTMLInputElement>,
        lineItemId: string,
        activeBulkActionsModule: BulkActionsModules,
        listingSummaryQuantity: number,
        itemDisabled?: boolean,
        transactionId?: string,
        variationId?: string,
        itemId?: string,
        displayPrice?: number,
        listingSummaries?: ShoppingCartListingSummary[],
        seller?: Seller
    ): void => {
        const isCheckboxMarked = e?.target?.checked;
        if (listingSummaries?.length) {
            for (const listingSummary of listingSummaries) {
                listingSummaryQuantity += extractQuantityFromListingSummary(listingSummary);
            }
        }

        if (isCheckboxMarked) {
            bulkActionItemsMap.set(lineItemId, {
                lineItemId: lineItemId,
                transactionId: transactionId,
                quantity: listingSummaryQuantity,
                variationId: variationId,
                itemId: itemId,
                displayPrice: displayPrice,
                lineItemSummaries: listingSummaries,
                seller: buildSellerObject(seller),
            });
        } else {
            setSelectAllCheckboxChecked(false);
            bulkActionItemsMap.delete(lineItemId);
        }
        const updatedMap = new Map(bulkActionItemsMap);
        setBulkActionItemsMap(updatedMap);
        handleBulkActionServiceCall(updatedMap);

        if (activeBulkActionsModule === 'CART') {
            if (itemDisabled && listingSummaryQuantity === 0) {
                listingSummaryQuantity = 1;
            }
            if (isCheckboxMarked) {
                const newCount = buttonQuantitySelected + listingSummaryQuantity;
                setButtonQuantitySelected(newCount);
            } else {
                const newCount = buttonQuantitySelected - listingSummaryQuantity;
                setButtonQuantitySelected(newCount);
            }
        }

        // Update Bulk XO order total
        if (cartBulkXoEnabled) {
            const {
                bulkXoDisplayPrice = 0
            } = extractLineItemsDetailsFromSellerbuckets(cartState?.modules?.cartDetails?.sellerBuckets, Array.from(updatedMap.keys()));
            updateBulkXoOrderTotal(bulkXoDisplayPrice);
        }
    };

    const isSessionCTADisabled = (): boolean => {
        if (cartBulkXoEnabled && bulkActionItemsMap?.size > 0) {
            return false;
        }
        return activeBulkActionsModule !== null;
    };

    const onBulkActionActiveModuleChange = (module: BulkActionsModules): void => {
        setActiveBulkActionsModule(module);
        setShouldRenderBulkActionsTourtip(false);
        setTourtipManuallyClosed(true);
    };

    const onClearBulkActionMap = (): void => {
        bulkActionItemsMap.clear();
    };

    const allCheckboxChecked = (lineItemIds: LineItem[]): void => {
        const updatedMap = new Map<string, LineItem>(
            lineItemIds.map(
                (lineItem: LineItem): [string, LineItem] => [lineItem?.lineItemId as string, { lineItemId: lineItem?.lineItemId }])
        );
        setBulkActionItemsMap(updatedMap);
    };

    const onSelectAllClick = (lineItems: LineItem[], isChecked: boolean): void => {
        if (!isChecked) {
            onClearBulkActionMap();
            setSelectAllCheckboxChecked(false);
            setButtonQuantitySelected(0);
            handleBulkActionServiceCall(bulkActionItemsMap, false);
            setSelectAllCheckboxClicked(!selectAllClicked);
        } else {
            allCheckboxChecked(lineItems);
            setSelectAllCheckboxClicked(!selectAllClicked);
            const {
                allItemsMap,
                quantityTotal = 0,
                bulkXoQuantity = 0,
                bulkXoDisplayPrice = 0
            } = extractLineItemsDetailsFromSellerbuckets(cartState?.modules?.cartDetails?.sellerBuckets, []);
            // Pass empty array as second param since on selectAll click we need all elements to be checked

            setSelectAllCheckboxChecked(true);
            setButtonQuantitySelected(cartBulkXoEnabled ? bulkXoQuantity : quantityTotal);
            updateBulkXoOrderTotal(bulkXoDisplayPrice);
            if (allItemsMap) {
                handleBulkActionServiceCall(allItemsMap, true);
            }
        }
    };


    const resetState = () => {
        onBulkActionActiveModuleChange(null);
        onClearBulkActionMap();
        setSelectAllCheckboxChecked(false);
        setButtonQuantitySelected(0);
    };

    return {
        activeBulkActionsModule,
        allItemsMap,
        bulkActionItemsMap,
        bulkXoOrderTotal,
        buttonQuantitySelected,
        cartBulkXoEnabled,
        costSummary,
        pricingCostSummary,
        isSessionCTADisabled,
        onBulkActionActiveModuleChange,
        onBulkActionCheckboxChange,
        onClearBulkActionMap,
        onSelectAllClick,
        quantityTotal,
        resetState,
        showItemDiscountsPricelinesLoadingState,
        showItemPricelinesLoadingState,
        selectAllCheckboxChecked,
        selectAllClicked,
        selectedBulkActionItems,
        setSelectAllCheckboxChecked,
        setSelectedBulkActionItems,
        setShouldRenderBulkActionsTourtip,
        setTourtipManuallyClosed,
        shoppingCartBulkActionsTourTip,
        shouldRenderBulkActionsTourtip,
        setPricingCostSummary,
        setCostSummary
    };
}